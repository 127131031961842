
/* You can add global styles to this file, and also import other style files */

:root {
  --colorText:rgb(181, 222, 250);
  --blue: #007bff;
  --indigo: #6610f2;
  --purple2: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --primaryNew: #1791c2;
  --primaryHover :#17769c;
  --primaryActive: #136485;
  --primaryDisabled: #246d8aa9;
  --secondaryNew: #6c757d;
  --secondaryHover: #5a5e63;
  --secondaryActive: #46484b;
  --secondaryDisabled: #4c4d4ea6;
  --success: #28a745;
  --successNew: #2a8f8a;
  --info: #17a2b8;
  --warning: #ffc107;
  --dangerNew: rgb(94, 36, 56);
  --dangerHover:rgb(77, 32, 47);
  --dangerActive:  rgb(75,10,33);
  --dangerDisabled: rgba(75, 28, 44, 0.651);
  --danger:#dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --favorites:#00EBC5;
  --fushia:rgb(170, 1, 138);
  --purple:rgb(107, 12, 90);
  --disabledPrintText:#575757;
  --disabledPrintBg:#cfcfcf;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Arial,  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}


/* Navbar */

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #686868;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1791c2;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
  width: 12px;
}
.wrapper {
  min-height:100%; /* 1 */
	position:relative;
  
 
  
}

*{
  margin:0;
  padding:0;
  }
  
   
  
  body, html{
    
    margin:0;
    padding:0;
    height:93.15%;
  } /* avant navbar fixe, 99.9*/

  body{
 
    background-image: url(src/assets/images/fond.png)!important;
    background-color: #000000!important;
    background-attachment: fixed;
    font-family: "arial"; 
    font-size: 16pt;

   
    
 }



h1{
  font-size: 20pt;
}

.page-header{
  /*height: 80px;*/
  font-family: "arial";
  font-size: 16pt;

}

.page-main {
  
  flex-grow:1;
  font-family: "arial";
  font-size: 16pt;
 

  min-height:auto;
  position:relative;
 

}

.page-footer{

  background-color: #000000; 
  color:#6e6e6e; 
  display: flex;
  font-family: "arial";
  font-size: 10pt;
  text-align: end;
  align-items: center;
  justify-content: flex-end;
  position:fixed;
   bottom:0;  /* on le colle en bas */
   width:100%;
     /* Hauteur du pied de page */
  }

  



  .error{
    color: var(--fushia);
  }
  
  
  .redirectToLogin .redirect {
    cursor: pointer;
    text-decoration: underline;
  }





.cards_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 100%;
  
}

.cards_wrap .card_item {
  width: 40%;
  padding: 10px;
  min-height: 100%;
}

.cards_wrap .card_inner {
  background:var(--dark) ;
  border-radius: 20px;
  min-height: 100%;
}

.cards_wrap .card_top {
  width: 100%;
  height: auto;
  padding: 10px;
  padding-bottom: 0;
  min-height: 100%;
}

.cards_wrap .card_bottom {
  padding: 15px;
  color:var(--colorText);
  min-height: 100%;
}

.cards_wrap .card_bottom .card_category {
  text-transform: uppercase;
  text-align: center;
  min-height: 100%;
}

.cards_wrap .card_bottom .card_info {
  padding: 10px;
 
  border: 1px dashed var(--primaryNew);
  color:rgb(181, 222, 250);
  min-height: 100%;
}

.cards_wrap .card_bottom .card_info .title {
  color: var(--primaryNew);
  font-size: 18px;
  min-height: 100%;
}

.cards_wrap .card_bottom .card_creator {
  text-align: center;
  min-height: 75%;
}
@media (max-width: 1920px) {
  .cards_wrap .card_item {
    width: 55%;
  }
}



@media (max-width: 1024px) {
  .cards_wrap .card_item {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .cards_wrap .card_item {
    width: 75%;
  }
}

@media (max-width: 528px) {
  .cards_wrap .card_item {
    width: 100%;
  }
}

/* captcha*/

.g-recaptcha
{
  filter: invert(0.85);
  display: inline-block;

  
}

/*messages d'erreur*/

.invalid-feedback{
  color: var(--fushia);
}


label {
  display: inline-block;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}
/*.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper>* {
  padding: 0px;
}*/




/*@media screen and (min-width: 550px) {
  .page-main {
    padding-left: 90px;
  }
}*/




/*.page-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 350px;
      background-image: url(./assets/images/background1.jpg) ;
      background-size: cover;
      background-repeat: repeat;
      background-size: 100%;
      background-position: center;
      opacity:1;
      background-color:#6e6e6e
    }*/



    




/* Pour les tables*/

table{
  background-color: var(--dark);
  color: white;
  /*border-radius: 20px; pour l'arrondi du bas. attention fonctionne avec tbody*/
  -webkit-overflow-scrolling: touch;
  
}

 td,th,tr,tbody  {
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-top: 1px solid white;
  
    
}

table tr:nth-child(odd) {
  background-color: var(--dark);
  --disabled:#5b6570;
  --accepted:var(--fushia);
}
table tr:nth-child(even) {
  background-color:#5b6570;
  --disabled:var(--dark);
  --accepted:var(--purple);
}

th, td {
  text-align: left;
  padding: 8px;
  
}

table th{
  background-color: var(--primaryHover); /* header en bleu*/
}

/*@media (max-width: 768px) {
  .table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
}

@media (max-width: 528px) {
  .table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
}*/

/*table th:first-child {
    border-radius: 20px 0 0 0; 

table th:last-child {
  border-radius: 0 10px 0 0;  
}*/















/* margin-top, margin-bottom, margin-left, margin-right idem pour padding*/


.imageflottante{
    float:left;
}





  


/* pagination*/
.my-pagination .ngx-pagination {
  color:white; 
   }
 

/*.my-pagination .ngx-pagination { 
    color: red!important;  
    border: 1px solid #ddd;
    background-color: red;
  }*/

  /* couleur quand on appuie*/
 
  /*.my-pagination  .ngx-pagination :active{ 
    color: red;  
    border: 1px solid #ddd;
  }*/
  
  .my-pagination  .ngx-pagination :hover:not(.active) {
    background-color: var(--primaryHover);
    color: white;}

  .my-pagination  .ngx-pagination .current {
    background: var(--primaryNew);
    color: white;
  }
  .my-pagination  .ngx-pagination a {
    
    color: white;
  }
  .my-pagination  .ngx-pagination a:hover {
    background-color: var(--primaryHover);
    color: white;}
    

  /* Styles pour la page Sign in */

.center{
    text-align: center;
}

.justify{
    text-align: justify;
}

.left{
    text-align: left;
}

.right{
    text-align: right;
}

#conteneurRowCenter /* centre avec espace */
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}



#conteneurRowRight
{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
}


.formFill {
    margin-bottom: 20px;
    width: 100%;
    
  }

.formControl {
    width: 100%;
    display: block;
    padding: 7px;
    border: 2px solid  #1792c263;
    outline: none;
    font-size: 19px;
    color: #fff;
    background: #1792c21f;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .formControl:focus {
    border: 2px solid #1791c2;
    
  }



  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    /*border: 1px solid transparent;*/
    padding: 0.375rem 0.75rem;
    font-size: 15pt;
    line-height: 1.5;
    border-radius: 0.25rem;
    /*transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;*/
  }
  
  
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none;
  }
  


  .btn-primary {
    color: #fff;
    background-color:var(--primaryNew);
    border-color: var(--primaryNew);
    box-shadow: none;
    border-radius:10px;
    padding: 10px;
    margin-top: 10px;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: var(--primaryHover);
    border-color: var(--primaryHover);
    box-shadow: none;
  }

  
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: var(--primaryActive);
    border-color: var(--primaryActive);
    box-shadow: none;
    
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: var(--primaryDisabled);
    border-color: var(--primaryDisabled);
    box-shadow: none;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primaryActive);
    border-color: var(--primaryActive);
    box-shadow: none;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }


  .btn-secondary {
    color: #fff;
    background-color: var(--secondaryNew);
    border-color:var(--secondaryNew);
    box-shadow: none;
    border-radius:10px;
    padding: 10px;
    margin-top: 10px;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color:var(--secondaryHover);
    border-color: var(--secondaryHover);
    box-shadow: none;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: var(--secondaryActive);
    border-color:var(--secondaryActive);
    box-shadow: none;
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: var(--secondaryDisabled);
    border-color: var(--secondaryDisabled);
    box-shadow: none;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color:var(--secondaryActive);
    border-color: var(--secondaryActive);
    box-shadow: none;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-danger {
    color: #fff;
    background-color:var(--dangerNew);
    border-color: var(--dangerNew);
    box-shadow: none;
    border-radius:10px;
    padding: 10px;
    margin-top: 10px;
  }
  
  .btn-danger:hover {
    color: #fff;
    background-color: var(--dangerHover);
    border-color: var(--dangerHover);
    box-shadow: none;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: var(--dangerActive);
    border-color: var(--dangerActive);
    box-shadow: none;
    
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: var(--dangerDisabled);
    border-color:var(--dangerDisabled);
    box-shadow: none;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color:var(--dangerActive);
    border-color: var(--dangerActive);
    box-shadow: none;
  }
  

  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

  

.btnClassic{
    border-radius: 10px;
    padding: 10px;
    /*height: 50%;*/
    width: 100%;
    background-color: #1791c2;
    border-color: #1791c2;
    outline: none;
    color: #ffffff;
    /*text-align: center;*/
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
   vertical-align: middle;
}

.btnClassic:hover{  
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  background-color: #17769c;
  border-color: #17769c;

  color: #ffffff;
  text-align: center;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
}

.btnClassic:active{  
    border-radius: 10px;
    padding : 10px;
    width: 100%;
    background-color: #136485;
    border-color: #136485;

    color: #ffffff;
    text-align: center;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }

  .btnClassic:active:after {
    background: transparent;
}







.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: none;
  border-radius:10px;
  padding: 10px;
  margin-top: 10px;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: none;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: none;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

/* Titres 

@font-face {
    font-family: 'myJokerman';
    src: url('./assets/llynIcons/fonts/Jokerman.ttf') format("truetype");
  }

.TitleBossCool,.TitleBossCoolLt {
    fill:#000000;
    letter-spacing:1.5;
    word-spacing:0;
    kerning:0;
    font-family:"myJokerman";
    font-size-adjust:0.172414;
    font-weight:700;
    font-style:normal;
    baseline-shift:baseline;
    font-stretch:0;
}

.TitleBossCool {
    font-size:38pt;
}
.TitleBossCoolLt {
    font-size:14pt;
}
*/

/* ESSAI */

@media (max-width: 800px) {
  .row {
    position: relative;
  }
}

@media (min-width: 10px){
	.dropdown-menu .dropdown-toggle:after{
		border-top: .3em solid transparent;
	    border-right: 0;
	    border-bottom: .3em solid transparent;
      border-left: .3em solid;
      
	}
	.dropdown-menu .dropdown-menu{
    margin-left:0; margin-right: 0;
   
	}
	.dropdown-menu li{
		position: relative;
	}
	.nav-item .submenu{ 
		display: none;
		position: absolute;
    left:100%; top:-7px;
    background-color:  #343a40;
    color:white;
    
	}
	
  

	.dropdown-menu > li:hover{ background-color: #686868 }
	.dropdown-menu > li:hover > .submenu{
    display: block;
    
	}
}





#toast-container > .toast-error{
  background-image: none;
  background-color: var(--dangerNew);
  color:white;
  text-align: center;
}

#toast-container > .toast-success{
  background-image: none;
  background-color: var(--dark);
  color:white;
  text-align: center;
}



::-webkit-scrollbar {
  background:  #1792c21f!important; 
  border-left: 1px solid#1792c263;
  
  
}

::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border: 1px solid #1792c263;
}


input[type=password]::-ms-reveal
{
  filter: invert(1);
}

.modal-content {
  position: relative;
  margin: auto!important;
  display: flex;
  flex-direction: column;
  width: 100%; 
  pointer-events: auto;
  background-color: transparent;
  border-style: none;

}
/*.modal-dialog {
  padding-top: 0px !important;
  left:0;
  right: 0;
  position: absolute;
  width: auto;
  margin: 0px !important;}*/

.modal-header{
  background-color:rgb(36, 36, 36); 
  color:white;
  border-style: none;
  text-align: center;
}

.modal-body{
  background-color: var(--gray-dark);
  color:white;
  border-style: none;
  
}

.modal-footer{
  background-color: var(--gray-dark);
  color:white;
  border-style: none;
  text-align: center;
  
 
 
}

.close,.close:hover {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  opacity: .5;
  border-style:none;
}

.modal-title{
  background-color:none;
  color:white;
  border-style: none;
  justify-content: center!important;
}



button.close {
  padding: 0;
  background-color: transparent;
}

.search{
background-color: #343a40;
color:white;
cursor: pointer;
border: 2px solid rgb(170, 1, 138)!important;
border-radius: 5px;
box-shadow: none;
padding: 5px;
width: 200px;
margin:auto;
font-size: 12pt;
}

.search:focus, .search.focus {
  outline: 0;
  box-shadow: none;
  margin:auto;
}

.colorTitle{
  color:var(--primaryNew)
}

.colorTitle2{
  color:var(--primaryActive)
}

.paddingLeft35{
  padding-left: 35px;
}
