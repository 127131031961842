@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?hjn1vr');
  src:  url('fonts/icomoon.eot?hjn1vr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?hjn1vr') format('truetype'),
    url('fonts/icomoon.woff?hjn1vr') format('woff'),
    url('fonts/icomoon.svg?hjn1vr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-iconPDF:before {
  content: "\e94c";
  color: white;
}

.icon-iconValiderPouce .path1:before {
  content: "\e94d";
  color: #158fc0;
}
.icon-iconValiderPouce .path2:before {
  content: "\e94e";
  color: var(--fushia);
  margin-left: -1em;
}
.icon-iconValiderPouce .path3:before {
  content: "\e94f";
  color: #fff;
  margin-left: -1em;
}

.icon-iconAccepterCGU .path1:before {
  content: "\e94d";
  color: #fff;
}
.icon-iconAccepterCGU .path2:before {
  content: "\e94e";
  color: #fff;
  margin-left: -1em;
}
.icon-iconAccepterCGU .path3:before {
  content: "\e94f";
  color: #fff;
  margin-left: -1em;
}

.icon-iconSignaler .path1:before {
  content: "\e944";
  color: #c677b7;
}
.icon-iconSignaler .path2:before {
  content: "\e945";
  color: var(--fushia);
  margin-left: -1em;
}
.icon-iconSignaler .path3:before {
  content: "\e946";
  color: #fff;
  margin-left: -1em;
}


.icon-iconFiltreOk .path1:before {
  content: "\e93d";
  color: #89b3c3;
}
.icon-iconFiltreOk .path2:before {
  content: "\e93e";
  color: #158fc0;
  margin-left: -1em;
}
.icon-iconFiltreOk .path3:before {
  content: "\e93f";
  color: #fff;
  margin-left: -1em;
}

.icon-iconFiltreKO .path1:before {
  content: "\e939";
  color: #89b3c3;
}
.icon-iconFiltreKO .path2:before {
  content: "\e93a";
  color: #158fc0;
  margin-left: -1em;
}
.icon-iconFiltreKO .path3:before {
  content: "\e93b";
  color: #d5220b;
  margin-left: -1em;
}
.icon-iconFiltreKO .path4:before {
  content: "\e93c";
  color: #fff;
  margin-left: -1em;
}











.icon-iconParametres:before {
  content: "\e943";
  color:  #fff;
}

.icon-iconTCValides .path1:before {
  content: "\e94a";
  color: #47b528;
}
.icon-iconTCValides .path2:before {
  content: "\e94b";
  color: #fff;
  margin-left: -1em;
}

.icon-iconTCAValider .path1:before {
  content: "\e947";
  color: #d00f0f;
}
.icon-iconTCAValider .path2:before {
  content: "\e948";
  color: #d00f0f;
  margin-left: -1em;
}
.icon-iconTCAValider .path3:before {
  content: "\e949";
  color: #fff;
  margin-left: -1em;
}

.icon-iconInviter .path1:before {
  content: "\e940";
  color: #158fc0;
}
.icon-iconInviter .path2:before {
  content: "\e941";
  color: var(--fushia);
  margin-left: -1em;
}
.icon-iconInviter .path3:before {
  content: "\e942";
  color: #fff;
  margin-left: -1em;
}

.icon-iconTriBas:before {
  content: "\e925";
  color:#fff;

}

.icon-iconTriBasCouleur .path1:before {
  content: "\e926";
  color: rgb(0, 228, 191);
}
.icon-iconTriBasCouleur .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color:#fff;
}
.icon-iconTriHaut:before {
  content: "\e932";
  color:#fff;
}
.icon-iconTriHautCouleur .path1:before {
  content: "\e933";
  color: rgb(0, 228, 191);
}
.icon-iconTriHautCouleur .path2:before {
  content: "\e935";
  margin-left: -1em;
  color: #fff;
}

.icon-iconAide:before {
  content: "\e924";
  color: #fff;
}

.icon-iconQuota .path1:before {
  content: "\e936";
  color: rgb(108, 110, 110);
}
.icon-iconQuota .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: var(--fushia);
}
.icon-iconQuota .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: #fff
}

.icon-iconAdmin .path1:before {
  content: "\e91f";
  color: #158fc0;
}
.icon-iconAdmin .path2:before {
  content: "\e922";
  color: var(--fushia);
  margin-left: -1em;
}
.icon-iconAdmin .path3:before {
  content: "\e923";
  color: #fff;
  margin-left: -1em;
}



.icon-iconTronches .path1:before {
  content: "\e91d";
  color: #158fc0;
}
.icon-iconTronches .path2:before {
  content: "\e91e";
  color: #fff;
  margin-left: -1em;
}

.icon-iconFavoris .path1:before {
  content: "\e916";
  color: #158fc0;
}
.icon-iconFavoris .path2:before {
  content: "\e917";
  color:var(--fushia);
  margin-left: -1em;
}
.icon-iconFavoris .path3:before {
  content: "\e918";
  color: #fff;
  margin-left: -1em;
}

.icon-iconFavorisTrue .path1:before {
  content: "\e916";
  color: #158fc0;
}
.icon-iconFavorisTrue .path2:before {
  content: "\e917";
  color:var(--favorites);
  margin-left: -1em;
}
.icon-iconFavorisTrue .path3:before {
  content: "\e918";
  color: #fff;
  margin-left: -1em;
}

.icon-iconCollegues .path1:before {
  content: "\e912";
  color: #158fc0;
}
.icon-iconCollegues .path2:before {
  content: "\e913";
  color: var(--fushia);
  margin-left: -1em;
}
.icon-iconCollegues .path3:before {
  content: "\e915";
  color: #fff;
  margin-left: -1em;
}

.icon-IconHorloge .path1:before {
  content: "\e90f";
  color:rgb(255, 0, 170);
}
.icon-IconHorloge .path2:before {
  content: "\e911";
  margin-left: -1em;
  color:#fff;
}

.icon-iconInfo:before {
  content: "\e910";
  color:#fff;
}

.icon-IconDuplicate .path1:before {
  content: "\e903";
  color: #720047;
}
.icon-IconDuplicate .path2:before {
  content: "\e90b";
  color: #fff;
  margin-left: -1em;
}

.icon-iconNotOwner .path1:before {
  content: "\e930";
  color:var(--disabled);
}
.icon-iconNotOwner .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: #ffff;
}
.icon-iconOwner .path1:before {
  content: "\e929";
  color: rgb(29, 176, 36);
}
.icon-iconOwner .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color:#ffff;
}
.icon-iconExterminate .path1:before {
  content: "\e90c";
  color:var(--fushia);
}
.icon-iconExterminate .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(87, 1, 70);
}
.icon-iconExterminate .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: #ffff;
}
.icon-iconVerifyEmail .path1:before {
  content: "\e906";
  color: var(--fushia);
}
.icon-iconVerifyEmail .path2:before {
  content: "\e907";
  margin-left: -1em;
  color:#ffff;
}
.icon-iconProfil .path1:before {
  content: "\e908";
  color: var(--fushia);
}
.icon-iconProfil .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: #ffff;
}
.icon-iconEffacer .path1:before {
  content: "\e902";
  color: var(--fushia);
}
.icon-iconEffacer .path2:before {
  content: "\e905";
  margin-left: -1.2392578125em;
  color: #ffff;
}
.icon-iconConnexionNew:before {
  content: "\e934";
}
.icon-iconAjouter .path1:before {
  content: "\e900";
  color: var(--fushia);
}
.icon-iconAjouter .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: #ffff;
}
.icon-iconDeconnexion:before {
  content: "\e90a";
  color: #ffff;
}
.icon-iconEdit:before {
  content: "\e904";
  color:#ffff;
}
.icon-iconInscription .path1:before {
  content: "\e919";
  color:var(--fushia);
}
.icon-iconInscription .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color:#ffff;
}
.icon-iconPrint .path1:before {
  content: "\e960";
  color:var(--fushia);
}
.icon-iconPrint .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: #ffff;
}
.icon-iconSupprimer .path1:before {
  content: "\e920";
  color:var(--fushia);
}
.icon-iconSupprimer .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: #ffff;
}
.icon-iconTravaux .path1:before {
  content: "\e927";
  color: rgb(138, 203, 219);
}
.icon-iconTravaux .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: var(--fushia);
}
.icon-iconTravaux .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(75, 110, 119);
}
.icon-iconTravaux .path4:before {
  content: "\e92c";
  margin-left: -1em;
  color:#ffff;
}
.icon-iconTravauxListe .path1:before {
  content: "\e91b";
  color:var(--fushia);
}
.icon-iconTravauxListe .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color:#ffff;
}
.icon-iconValider .path1:before {
  content: "\e92d";
  color: rgb(171, 3, 139);
}
.icon-iconValider .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color:#ffff;
}
.icon-iconRetour:before {
  content: "\e914";
  color:#ffff;
}